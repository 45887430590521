import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import EventTreeMxGraph from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/EventTreeMxGraph';
import FaultTreeMxGraph from 'hcl-web-editor/app/mxGraph/HCLTreeMxGraph/FaultTreeMxGraph';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Switch, Theme, Tooltip } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import GpsFixedTwoToneIcon from '@material-ui/icons/GpsFixedTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {
  mxCellHighlight,
  mxCell,
  mxGraphLayout,
  mxEvent,
} from 'mxgraph-js';
import HCLTreeVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeVertex';
import useTheme from '@material-ui/core/styles/useTheme';
import DecisionPointVertex from '../../../MxGraph/HCLTreeNode/PhoenixFlowchartNode/DecisionPointVertex';
import HCLTreeEdge from 'hcl-web-editor/app/mxGraph/HCLTreeNode/HCLTreeEdge';
import PhoenixFlowchartMxGraphDecoder from '../../../MxGraph/PhoenixFlowchart/PhoenixFlowchartMxGraphDecoder';
import DesignBasisScenarioLayout from '../../../MxGraph/PhoenixFlowchart/DesignBasisScenarioLayout';
import MaintenanceScenarioLayout from '../../../MxGraph/PhoenixFlowchart/MaintenanceScenarioLayout';
import Phoenix2ScenarioLayout from '../../../MxGraph/PhoenixFlowchart/Phoenix2ScenarioLayout';
import BranchPointVertex from '../../../MxGraph/HCLTreeNode/PhoenixFlowchartNode/BranchPointVertex';
import InitVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/InitEvent/InitVertex';
import EndStateVertex from 'hcl-web-editor/app/mxGraph/HCLTreeNode/EventTreeNode/EndStateNode/EndStateVertex';
import PhoenixEndStateVertex from '../../../MxGraph/HCLTreeNode/PhoenixFlowchartNode/PhoenixEndStateVertex';
import { useIntl } from 'react-intl';
import { UserPreferencesContext } from 'hcla-web-frontend-primitives/app/config/PreferencesProvider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { useParams } from 'react-router-dom';
import PhoenixApiManager from '../../../Api/PhoenixApiManager.ts';
import { findConnections } from '../../../MxGraph/PhoenixFlowchart/FlowChartHelper';
import NewPhoenixFlowchartMxGraphDecoder from '../../../MxGraph/PhoenixFlowchart/NewPhoenixFlowChartDecoder';
import NewPhoenix3Layout from '../../../MxGraph/PhoenixFlowchart/NewPhoenix3Layout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: 1,
    display: 'flex',
    borderStyle: 'solid',
    borderColor: theme.palette.text.primary,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },

  content: {
    height: '100%',
    width: '100%', // not actually affecting the trees/graphs. Other file in /SinglePhoenixModelView is
  },

  controller: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: theme.spacing(1),
    },
  },

  buttonGroup: {
    background: theme.palette.background.paper,
    borderRadius: '20%',
    boxShadow:
      '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
  },

  zoomInButton: {
    borderRadius: '20% 20% 0 0',
  },

  zoomOutButton: {
    borderRadius: '0 0 20% 20%',
  },

  targetButton: {
    borderRadius: '20%',
  },

  center: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  paper: { maxWidth: '100px' },
}));

type PhoenixTreeMxGraphViewProps = {
  graph:
    | PhoenixFaultTreeMxGraph
    | PhoenixEventTreeMxGraph
    | PhoenixFlowChartMxGraph;
  target?: string | string[]; // key of the cell to highlight
};

export const PhoenixTreeMxGraphView = (
  props: PhoenixTreeMxGraphViewProps
) => {
  // Root Caller

  const { graph, target, cfId } = props;

  const classes = useStyles();

  const [notes, setMiscNotes] = useState({});
  const [miscBPData, setMiscBPData] = useState({});

  const getNotes = () => {
    const { cfId } = props;
    // let officialMiscNotes;
    console.log(cfId, 'thecf');

    PhoenixApiManager.openMainModelNote(cfId).then((res) => {
      setMiscNotes(res.data.misc.notes);
    });
  };

  const getBPs = (cfId, newobj) => {
    let miscObj;
    PhoenixApiManager.openMainModelNote(cfId).then((res) => {
      // setCompletemisc(res.data.misc)
      savePayload(res.data.misc, newobj);
      // setMiscNotes(res.data.misc.notes)
    });
  };

  const savePayload = (completeMisc, newObj) => {
    if (props.graph.newActiveBPs.length) {
      const finalNotes = getNotes();
      // console.log(finalNotes,' this is misc notes ', notes, 'work', newObj)
      // console.log('return?', completeMisc)

      PhoenixApiManager.partialUpdateMainModel(cfId, {
        misc: { ...completeMisc, ...newObj },
      })
        .then((res) => {
          setMiscBPData({ misc: { ...completeMisc, ...newObj } });
          setMiscNotes(res.data.misc.notes);
        })
        .catch((err) => console.log(err));
    } else {
      // have logic for sending post request into misc key
      const finalNotes = getNotes();

      PhoenixApiManager.partialUpdateMainModel(cfId, {
        misc: { notes, ...newObj },
      })
        .then((res) => {
          setMiscNotes(res.data.misc.notes);
        })
        .catch((err) => console.log(err));
    }
  };

  if (!graph) {
    // outside of analysis page, there is no graph being passed into props?
    return (
      <div className={classes.root}>
        {/* call the loading here, but the loading does nothing to progress anything */}
        <PhoenixTreeMxGraphLoadingView />
      </div>
    );
  }

  return (
    <UserPreferencesContext.Consumer>
      {(value: any) => {
        graph.setPreferencesProvider(value.provider);
        return (
          <div className={classes.root}>
            <PhoenixTreeMxGraphContentView
              getNotes={getNotes}
              graph={graph}
              target={target}
              savePayload={savePayload}
              getBPs={getBPs}
              cfId={cfId}
              notes={notes}
              miscBPData={miscBPData}
            />
          </div>
        );
      }}
    </UserPreferencesContext.Consumer>
  );
};

const PhoenixTreeMxGraphLoadingView: FunctionComponent = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.center}>
      <CircularProgress />
      {/* stuck on this when calling from pages other than analysis */}
      <Typography>
        {intl.formatMessage({ id: 'loadingTheGraph' })}
      </Typography>
    </div>
  );
};

const TEMPLATE = {
  DESIGN_BASIS_SCENARIO: 'Design Basis Scenarios',
  DESIGN_BASIS_SCENARIO_TEMPLATE: 'Design Basis Scenarios Template',
  MAINTENANCE_SCENARIO: 'Maintenance Scenarios',
  Phoenix2_SCENARIO: 'Phoenix 2.0',
  Phoenix2_1_SCENARIO: 'Phoenix 2.1',
};

export class PhoenixEventTreeMxGraph extends EventTreeMxGraph {
  // this one is scaled fine

  private highlighters: mxCellHighlight[];
  private data: any;

  constructor(data: any) {
    super(
      () => this.onGraphMounted(data),
      () => {}
    );
    // const theme = useTheme();
    this.highlighters = [];
    this.data = data;
  }

  /**
   * Highlight target cell
   * This will dehighlight the previous highlighted cell
   * @param target {mxCell} - target cell to highlight
   */
  highlight(target: mxCell[]) {
    this.highlighters.forEach((highlighter) => highlighter.destroy());
    this.highlighters = [];

    target.forEach((el) => {
      const targetHighlighter = new mxCellHighlight(
        this,
        'red',
        2,
        true
      );
      this.highlighters.push(targetHighlighter);
      targetHighlighter.highlight(this.view.getState(el));
    });
    // this.highlighter.highlight(this.view.getState(target))
  }

  /**
   * Translate to target cell such that target cell is located
   * at the center of the graph container
   * @param target {mxCell} - target cell to translate to
   */
  translateTo(target: mxCell[]) {
    const scale = this.getView().scale;
    const middle = target[Math.floor(target.length / 2)];
    const x =
      -middle.geometry.x -
      middle.geometry.width / 2 +
      this.container.clientWidth / 2 / scale;
    const y =
      -middle.geometry.y -
      middle.geometry.height / 2 +
      this.container.clientHeight / 2 / scale;
    this.getView().setTranslate(x, y);
  }

  onGraphMounted(data: any) {
    this.populateFromJSON(data);
    this.setPanning(true);
    this.applyDefaultLayout();
    this.setEnabled(false);
  }

  remountGraph(data: any) {
    this.removeAllVertices();
    this.updateAnswerResponse(data);
    this.forceSetIdsVisible(false);
    this.setPanning(true);
    this.getDefaultLayout();
    this.setEnabled(false);
  }
}

export class PhoenixFaultTreeMxGraph extends FaultTreeMxGraph {
  private highlighters: mxCellHighlight[];
  private rootKey: string;

  constructor(
    data: any,
    onCellClick?: (cell: mxCell) => void,
    onCellsPopulated?: () => void
  ) {
    super(
      () => {
        this.onGraphMounted(data);
        if (onCellsPopulated) onCellsPopulated();
      },
      () => {}
    );
    // const theme = useTheme();
    this.highlighters = [];
    // conditional statement to recognize Expression linked BE
    this.rootKey = data?.top_node
      ? data?.top_node.name
      : data?.label.name;
    this.addClickListener(onCellClick);
  }

  private addClickListener(callback: (cell: mxCell) => void) {
    this.addListener(mxEvent.CLICK, (sender: any, evt: any) => {
      const cell = evt.getProperty('cell');
      callback(cell);
      evt.consume();
    });
  }

  getRootKey() {
    return this.rootKey;
  }

  rerender(cell: mxCell) {
    this.getView().clear(cell, false, false);
    this.getView().validate();
  }

  /**
   * Highlight target cell
   * This will dehighlight the previous highlighted cell
   * @param target {mxCell} - target cell to highlight
   */
  highlight(target: mxCell[]) {
    this.highlighters.forEach((highlighter) => highlighter.destroy());
    this.highlighters = [];

    target.forEach((el) => {
      const targetHighlighter = new mxCellHighlight(
        this,
        'red',
        2,
        true
      );
      this.highlighters.push(targetHighlighter);
      targetHighlighter.highlight(this.view.getState(el));
    });
    // this.highlighter.highlight(this.view.getState(target))
  }
  /**
   * Translate to target cell such that target cell is located
   * at the center of the graph container
   * @param target {mxCell} - target cell to translate to
   */
  translateTo(target: mxCell[]) {
    const scale = this.getView().scale;
    const middle = target[Math.floor(target.length / 2)];
    const x =
      -middle.geometry.x -
      middle.geometry.width / 2 +
      this.container.clientWidth / 2 / scale;
    const y =
      -middle.geometry.y -
      middle.geometry.height / 2 +
      this.container.clientHeight / 2 / scale;
    this.getView().setTranslate(x, y);
  }

  onGraphMounted(data: any) {
    this.populateFromJSON(data);
    this.forceSetIdsVisible(false);
    this.setPanning(true);
    this.applyDefaultLayout();
    this.setEnabled(false);
  }
}

export class PhoenixFlowChartMxGraph extends PhoenixEventTreeMxGraph {
  private templateName: string;
  public activeBPs: Array;
  public newActiveBPs: Array;
  private nYesDPs: number;
  private nNoDPs: number;
  private lastAnsweredDP: string;
  private lastAnswer: string;
  public answeredDecisionPoints: any;
  public decoder: NewPhoenixFlowchartMxGraphDecoder;

  constructor(
    data: any,
    templateName: string,
    answeredDecisionPoints: any
  ) {
    super(data);
    this.templateName = templateName;
    this.activeBPs = [];
    this.newActiveBPs = [];
    this.nYesDPs = 0;
    this.nNoDPs = 0;
    this.lastAnsweredDP = '';
    this.lastAnswer = '';
    this.answeredDecisionPoints = answeredDecisionPoints;
    this.decoder = null;
  }

  populateFromJSON() {
    this.decoder = new NewPhoenixFlowchartMxGraphDecoder(
      this,
      findConnections(this.answeredDecisionPoints)
    );
    this.decoder.decode();
  }

  updateAnswerResponse(inputJSON: any) {
    this.decoder = new NewPhoenixFlowchartMxGraphDecoder(
      this,
      findConnections(inputJSON)
    );
    this.decoder.decode();
    this.getDefaultLayout();
  }

  getDefaultLayout(): mxGraphLayout {
    switch (this.templateName) {
      case TEMPLATE.DESIGN_BASIS_SCENARIO_TEMPLATE:
      case TEMPLATE.DESIGN_BASIS_SCENARIO:
        return new DesignBasisScenarioLayout(this);
      case TEMPLATE.MAINTENANCE_SCENARIO:
        return new MaintenanceScenarioLayout(this);
      case TEMPLATE.Phoenix2_1_SCENARIO:
      case TEMPLATE.Phoenix2_SCENARIO:
        return new NewPhoenix3Layout(this);
      default:
        return new NewPhoenix3Layout(this);
    }

    return super.getDefaultLayout();
  }

  setStyleForCells(
    cells: mxCell[],
    style: { [key: string]: string }
  ) {
    const model = this.getModel();
    cells.forEach((v) => {
      const prevStyles = v.getStyle();
      model.setStyle(
        v,
        prevStyles + ';' + HCLTreeVertex.mapStyles(style)
      );
    });
  }

  setStyleForVerticesWithKeys(
    keys: string[],
    style: { [key: string]: string }
  ) {
    keys.forEach((k) =>
      this.setStyleForCells(this.getVerticesWithKey(k), style)
    );
  }

  applyDecisionPointStyles(
    answeredDecisionPointNames: { yes: string[]; no: string[] },
    activeDecisionPointName: string
  ) {
    const decisionPoints = this.getVertices().filter(
      (v) => v instanceof DecisionPointVertex
    );
    const activeDecisionPointNames = [
      ...answeredDecisionPointNames.yes,
      ...answeredDecisionPointNames.no,
      activeDecisionPointName,
    ];
    const inactiveDecisionPointNames = decisionPoints
      .filter((dp) => !activeDecisionPointNames.includes(dp.getKey()))
      .map((d) => d.getKey());

    // ***************************************
    if (answeredDecisionPointNames.yes.length > this.nYesDPs) {
      this.lastAnsweredDP =
        answeredDecisionPointNames.yes[
          answeredDecisionPointNames.yes.length - 1
        ];
      this.lastAnswer = 'yes';
      this.nYesDPs = answeredDecisionPointNames.yes.length;
    }
    if (answeredDecisionPointNames.no.length > this.nNoDPs) {
      this.lastAnsweredDP =
        answeredDecisionPointNames.no[
          answeredDecisionPointNames.no.length - 1
        ];
      this.lastAnswer = 'no';
      this.nNoDPs = answeredDecisionPointNames.no.length;
    }

    let a = this.lastAnsweredDP.split('_');
    let DPnode = a.slice(0, 2).join('_');
    let suffix = '';
    if (a.length == 3) {
      suffix = '_' + a[2];
    }

    let allBPs = this.getVertices().filter(
      (v) => v instanceof BranchPointVertex
    );

    let tempActiveBPs = [];

    let getNextBPs = (currentBPKey) => {
      let allBPs = this.getVertices().filter(
        (v) => v instanceof BranchPointVertex
      );
      for (let bp of allBPs) {
        if (!bp.edges) bp.edges = [];
        let hasIncomingActiveEdge =
          bp.edges.filter(
            (e) => e.target === bp && e.source.key == currentBPKey
          ).length > 0;
        if (hasIncomingActiveEdge) {
          tempActiveBPs.push(bp);
          getNextBPs(bp.key);
        }
      }
    };

    for (let bp of allBPs) {
      if (!bp.edges) bp.edges = [];
      const hasIncomingActiveEdge =
        bp.edges.filter(
          (e) =>
            e.target === bp &&
            e.source.key == DPnode &&
            e.value == this.lastAnswer
        ).length > 0;
      if (hasIncomingActiveEdge) {
        tempActiveBPs.push(bp);
        getNextBPs(bp.key);
      }
    }

    let activeBPs = tempActiveBPs.map((bp) => bp.key + suffix);
    this.newActiveBPs = activeBPs.slice();

    console.log(activeBPs, 'this is the active bp');
    // ***************************************

    this.setStyleForVerticesWithKeys(
      answeredDecisionPointNames.yes,
      DecisionPointVertex.yesStyle
    );
    this.setStyleForVerticesWithKeys(
      answeredDecisionPointNames.no,
      DecisionPointVertex.noStyle
    );
    this.setStyleForVerticesWithKeys(
      [activeDecisionPointName],
      DecisionPointVertex.activeStyle
    );
    this.setStyleForVerticesWithKeys(
      inactiveDecisionPointNames,
      DecisionPointVertex.inactiveStyle
    );

    // for each yes - type DP set all:
    // 1. outgoing success edges to active
    // 2. outgoing failure edges to inactive
    answeredDecisionPointNames.yes.forEach((y) => {
      this.getVerticesWithKey(y).forEach((v) => {
        if (!v.edges) return;
        v.edges.forEach((e) => {
          if (e.source === v) {
            e.active = e.value === 'yes';
            this.setStyleForCells(
              [e],
              e.active
                ? HCLTreeEdge.activeStyle
                : HCLTreeEdge.inactiveStyle
            );
          }
        });
      });
    });

    // for each no - type DP set all:
    // 1. outgoing success edges to inactive
    // 2. outgoing failure edges to active
    answeredDecisionPointNames.no.forEach((n) => {
      this.getVerticesWithKey(n).forEach((v) => {
        if (!v.edges) return;
        v.edges.forEach((e) => {
          if (e.source === v) {
            e.active = e.value === 'no';
            this.setStyleForCells(
              [e],
              e.active
                ? HCLTreeEdge.activeStyle
                : HCLTreeEdge.inactiveStyle
            );
          }
        });
      });
    });

    // for each active-type DP set all:
    // 1. outgoing edges to inactive.
    this.getVerticesWithKey(activeDecisionPointName).forEach((v) => {
      if (!v.edges) return;
      v.edges.forEach((e) => {
        if (e.source === v) {
          e.active = false;
          this.setStyleForCells([e], HCLTreeEdge.inactiveStyle);
        }
      });
    });

    // for each inactive-type - DP set all:
    // 1. outgoing and incoming edges to inactive.
    inactiveDecisionPointNames.forEach((dp) => {
      this.getVerticesWithKey(dp).forEach((v) => {
        if (!v.edges) return;
        v.edges.forEach((e) => {
          e.active = false;
          this.setStyleForCells([e], HCLTreeEdge.inactiveStyle);
        });
      });
    });
  }

  applyBranchPointStyles() {
    const allBPs = this.getVertices().filter(
      (v) => v instanceof BranchPointVertex
    );

    let tempActiveBPs = [];
    let a = this.lastAnsweredDP.split('_');

    let suffix = '';
    if (a.length == 3) {
      suffix = '_' + a[2];
    }

    let getNextBPs = (currentBPKey) => {
      let allBPs = this.getVertices().filter(
        (v) => v instanceof BranchPointVertex
      );
      for (let bp of allBPs) {
        if (!bp.edges) bp.edges = [];
        let hasIncomingActiveEdge =
          bp.edges.filter(
            (e) => e.target === bp && e.source.key == currentBPKey
          ).length > 0;
        if (hasIncomingActiveEdge) {
          tempActiveBPs.push(bp);
          getNextBPs(bp.key);
        }
      }
    };

    for (let bp of allBPs) {
      if (!bp.edges) bp.edges = [];
      const hasIncomingActiveEdge =
        bp.edges.filter(
          (e) => e.target === bp && e.value == this.lastAnswer
        ).length > 0;
      if (hasIncomingActiveEdge) {
        tempActiveBPs.push(bp);
        getNextBPs(bp.key);
      }
    }
    let allBPKeys = allBPs.map((obj) => obj.key);

    let activeBPs = tempActiveBPs.map((bp) => bp.key + suffix);
    let slicedActiveBP = activeBPs.slice();
    this.newActiveBPs = [...new Set(slicedActiveBP)];
    // this.newActiveBPs = slicedActiveBP

    //console.log(allBPs)
    //console.log(allDPs)
    // for every BP
    // 0. begin by setting the BP to inactive and setting all its outgoing edges to inactive
    // 1. if BP has an incoming active edge:
    //    1a. set BP to active
    //    1b. set that BPs outgoing edges to active

    allBPs.forEach((bp) => {
      if (!bp.edges) bp.edges = [];
      const hasIncomingActiveEdge =
        bp.edges.filter((e) => e.target === bp && e.active).length >
        0;
      const outgoingEdges =
        bp.edges.filter((e) => e.source === bp) || [];
      if (hasIncomingActiveEdge) {
        this.setStyleForCells([bp], BranchPointVertex.activeStyle);
        outgoingEdges.forEach((e) => {
          e.active = true;
          // this.setStyleForCells([e], HCLTreeEdge.activeStyle);
        });
      } else {
        // this will be for our new layout
        this.setStyleForCells([bp], BranchPointVertex.activeStyle);
        outgoingEdges.forEach((e) => {
          e.active = false;
          // this.setStyleForCells([e], HCLTreeEdge.activeStyle);
        });
      }
    });
  }

  applyInitEventStyles = () => {
    const allInits = this.getVertices().filter(
      (v) => v instanceof InitVertex
    );
    allInits.forEach((v) => {
      this.setStyleForCells(v.edges, HCLTreeEdge.activeStyle);
    });
  };

  applyEndStateStyles = () => {
    const allEndStates = this.getVertices().filter(
      (v) => v instanceof EndStateVertex
    );
    allEndStates.forEach((es) => {
      const inEdge =
        es.edges &&
        es.edges.filter((e) => e.target === es && e.active).length >
          0;
      this.setStyleForCells(
        [es],
        inEdge
          ? PhoenixEndStateVertex.activeStyle
          : PhoenixEndStateVertex.activeStyle
      );
    });
  };
}

type PhoenixTreeMxGraphViewContentProps = {
  graph:
    | PhoenixFaultTreeMxGraph
    | PhoenixFlowChartMxGraph
    | PhoenixEventTreeMxGraph;
  target?: string | string[];
};

const PhoenixTreeMxGraphContentView = (
  props: PhoenixTreeMxGraphViewContentProps
) => {
  // Calls controller

  const { graph, target, cfId, getNotes, getBPs, notes, miscBPData } =
    props;
  const classes = useStyles();
  const ref = useRef(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [text, setText] = useState('');
  const [currentKey, setCurrentKey] = useState('');
  const [ranOnce, setRanOnce] = useState(false); // using this so that don't have to click twice for first save & continue pop-up
  const [bpData, setBpData] = useState({}); // Initialize an empty object to store data for each BP
  const [bpNotes, setBpNotes] = useState({}); // Initialize an empty object to store notes for each BP

  useEffect(() => {
    graph.mount(ref.current);
    return () => graph.unmount();
  }, []);

  useEffect(() => {
    if (target) {
      let targetVertices: any[] = [];

      (Array.isArray(target) ? target : [target]).forEach((key) => {
        if (!graph.getVerticesMap()[key]) return;
        targetVertices.push(graph.getVerticesMap()[key][0]);
      });
      if (targetVertices.length > 0) {
        graph.highlight(targetVertices);
        graph.translateTo(targetVertices);
      }
    }
  }, [target]);

  useEffect(() => {
    switch (currentKey) {
      case 'BP_A':
        setText(
          'Manual initiation of the CF before it is automatically initiated'
        );
        break;
      case 'BP_B':
        setText('Automatic initiation of the CF');
        break;
      case 'BP_C':
        setText(
          'The crew does not manually turn off the automatically initiated CF'
        );
        break;
      case 'BP_D':
        setText(
          'Availability of relevant instruments for the Crew awareness on the CF'
        );
        break;
      case 'BP_D1':
        setText(
          'Availability of relevant instruments for the Crew awareness on the CF'
        );
        break;
      case 'BP_D2':
        setText(
          'Availability of relevant instruments for the Crew awareness on the CF'
        );
        break;
      case 'BP_E':
        setText('Crew awareness of the CF');
        break;
      case 'BP_E1':
        setText('Crew awareness of the CF');
        break;
      case 'BP_E2':
        setText('Crew awareness of the CF');
        break;
      case 'BP_F':
        setText('The crew is on the correct procedure');
        break;
      case 'BP_G':
        setText('Equipment availability');
        break;
      case 'BP_G_1':
        setText('Equipment availability');
        break;
      case 'BP_H':
        setText('The crew correctly performs the procedural step');
        break;
      case 'BP_H_1':
        setText('The crew correctly performs the procedural step');
        break;
      case 'BP_H_2':
        setText('The crew correctly performs the procedural step');
        break;
      case 'BP_I':
        setText(
          'Continuation of procedural steps after failure in non-critical step'
        );
        break;
      case 'BP_I_1':
        setText(
          'Continuation of procedural steps after failure in non-critical step'
        );
        break;
      case 'BP_J':
        setText(
          'The crew does not exit procedure before the critical function is achieved'
        );
        break;
      case 'BP_K':
        setText(
          'Continuation of procedural steps after failure of non-critical equipment'
        );
        break;
      case 'BP_HRec':
        setText(
          'The crew recovers from failure in critical procedural step'
        );
        break;
      case 'BP_HRec_1':
        setText(
          'The crew recovers from failure in critical procedural step'
        );
        break;
      case 'BP_HRec_2':
        setText(
          'The crew recovers from failure in critical procedural step'
        );
        break;
      case 'BP_GRec':
        setText('Recovery from critical equipment unavailability');
        break;
      case 'BP_GRec_1':
        setText('Recovery from critical equipment unavailability');
        break;
      case 'BP_GRec_2':
        setText('Recovery from critical equipment unavailability');
        break;
      default:
        setText('');
        break;
    }
  }, [currentKey]); // add this props so that it does it on first time too?

  const isItSaved = () => {
    let lastBPkey =
      props.graph.newActiveBPs[props.graph.newActiveBPs.length - 1];
  };

  useEffect(() => {
    if (props.graph.newActiveBPs) {
      // Filtering out BPs that are already present in miscBPData
      const nonExistingBPs = props.graph.newActiveBPs.filter(
        (bp) => !props.miscBPData.misc?.hasOwnProperty(bp)
      );

      if (nonExistingBPs.length) {
        setCurrentKey(nonExistingBPs[0]); // Setting the first non-existing BP as the current key
        getNotes();
        setShowPopUp(true);
        setRanOnce(true);
      } else {
        setShowPopUp(false);
      }
    }
  }, [props.graph.newActiveBPs]);

  const handleSave = () => {
    // Log the current state of miscBPData

    // Send post request logic for misc key (if necessary)

    // Update the BPs
    props.getBPs(cfId, { [currentKey]: text });

    const vertex = props.graph
      .getVertices()
      .find((v) => v.key === currentKey);

    const updateLabel = (vertex: object, text: string) => {
      vertex.value.label.name = text;
    };
    updateLabel(vertex, text);

    // Check the index of currentKey in newActiveBPs
    const keyIndex = props.graph.newActiveBPs.indexOf(currentKey);

    // If the currentKey is the last in the newActiveBPs array, hide the popup
    if (keyIndex === props.graph.newActiveBPs.length - 1) {
      setShowPopUp(false);
    } else {
      // Set the next key as the current key
      setCurrentKey(props.graph.newActiveBPs[keyIndex + 1]);
    }
    props.graph.view.refresh();

    // Remove the currentKey from props.miscBPData
    // props.miscBPData = props.miscBPData.filter(item => item !== currentKey);
  };

  return (
    <React.Fragment>
      <div
        className={classes.content}
        ref={ref}
        style={{ width: '100%' }}
      />
      {props.graph.newActiveBPs && (
        <Dialog
          open={showPopUp}
          // onClose={() => {setShowPopUp(false)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            top: 0,
            right: 0,
            bottom: 600,
            left: 'auto',
            position: 'fixed',
            minHeight: '300px',
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {'Label Events (Branch Points)'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* line below is what creates the dialog, and works with current key state */}
              {`Please re-label the following events (CRT Branch Points):\n${currentKey}`}
              <TextField
                className={classes.entryTextField}
                type="text"
                // disabled={editingDisabled}
                variant="outlined"
                fullWidth
                required
                value={text}
                onChange={(e) => setText(e.target.value)}
                margin="dense"
                // label={intl.formatMessage({ id: 'analysis.bp.label' })}
                rows={2}
                multiline
                autoFocus
                // defaultValue={entry.label.name}
                // onChange={e => this.handleLabelChange(e, description)}
              />
              <Button
                fullWidth
                id='save-btn'
                // disabled={cf.crt_model === null ? true : false}
                color={'primary'}
                variant={'contained'}
                style={{
                  borderBottom: '2px solid black',
                  marginTop: '1em',
                  maxheight: '10px',
                }}
                onClick={() => handleSave()}
              >
                Save and Continue
              </Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      <PhoenixTreeMxGraphController graph={graph} target={target} />
    </React.Fragment>
  );
};

type PhoenixTreeMxGraphControllerProps = {
  graph: PhoenixEventTreeMxGraph | PhoenixFaultTreeMxGraph;
  target?: string | string[];
};

const PhoenixTreeMxGraphController: FunctionComponent<
  PhoenixTreeMxGraphControllerProps
> = (props) => {
  // controls graph

  const { graph, target } = props;
  const classes = useStyles();

  const [descriptions, setDescriptions] = useState<boolean>(
    graph.getDescriptionEnabled()
  );

  return (
    <div className={classes.controller}>
      <PhoenixTreeMxGraphTargetController
        graph={graph}
        target={target}
      />
      <PhoenixTreeMxGraphZoomController graph={graph} />
      <PhoenixTreeMxGraphDescriptionsController graph={graph} />
    </div>
  );
};

const PhoenixTreeMxGraphZoomController: FunctionComponent<
  PhoenixTreeMxGraphControllerProps
> = (props) => {
  const { graph } = props;
  const classes = useStyles();

  return (
    <ButtonGroup
      className={classes.buttonGroup}
      orientation="vertical"
      size="small"
      variant="text"
    >
      <Button
        className={classes.zoomInButton}
        onClick={() => graph.zoomIn()}
      >
        <AddIcon />
      </Button>
      <Button
        className={classes.zoomOutButton}
        onClick={() => graph.zoomOut()}
      >
        <RemoveIcon />
      </Button>
    </ButtonGroup>
  );
};

const PhoenixTreeMxGraphTargetController: FunctionComponent<
  PhoenixTreeMxGraphControllerProps
> = (props) => {
  const classes = useStyles();
  const { graph, target } = props;

  return (
    <ButtonGroup
      className={classes.buttonGroup}
      orientation="vertical"
      size="small"
      variant="text"
    >
      <Button
        className={classes.targetButton}
        onClick={() => {
          let targetVertices: any[] = [];

          (Array.isArray(target) ? target : [target]).forEach(
            (key) => {
              if (!graph.getVerticesMap()[key]) return;
              targetVertices.push(graph.getVerticesMap()[key][0]);
            }
          );
          graph.translateTo(targetVertices);
        }}
      >
        <GpsFixedTwoToneIcon />
      </Button>
    </ButtonGroup>
  );
};

const PhoenixTreeMxGraphDescriptionsController: FunctionComponent<
  PhoenixTreeMxGraphControllerProps
> = (props) => {
  const { graph } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [isDescriptionsEnabled, setIsDescriptionsEnabled] =
    useState<boolean>(graph.getDescriptionEnabled());

  const handleChange = () => {
    setIsDescriptionsEnabled(!isDescriptionsEnabled);
  };

  useEffect(() => {
    graph.setDescriptionEnabled(isDescriptionsEnabled);
  }, [isDescriptionsEnabled]);

  return (
    <div className={classes.buttonGroup}>
      <Tooltip title={intl.formatMessage({ id: 'nodeDescriptions' })}>
        <Switch
          checked={isDescriptionsEnabled}
          onChange={handleChange}
          color={'primary'}
        />
      </Tooltip>
    </div>
  );
};
