import React from 'react';
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles";
import {useIntl} from "react-intl";
import {Card} from "@material-ui/core";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";

interface Props {
    basicEvents: {
        [key: string]: {
            label?: { name: string };
            expression: { value: string };
        };
    };
    isHFEEmpty: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    expansionPanelExpanded: {
        margin: '0 !important',
        minHeight: '100%'
    },
    chipRoot: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    eventContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1)
    },
    equalSign: {
        margin: '0 8px',
        fontWeight: 'bold'
    }
}));

const SelectedBasicEvents: React.FC<Props> = ({ basicEvents, isHFEEmpty }) => {
    const classes = useStyles();
    const intl = useIntl();

    const nonCFMEvents = Object.entries(basicEvents).filter(([key]) => !key.includes("CFM -"));

    const renderContent = () => {
        if (!isHFEEmpty && nonCFMEvents.length > 0) {
            return (
                <Grid item xs={12}>
                    {nonCFMEvents.map(([key, event]) => {
                        const label = event.label?.name || key;
                        const expressionValue = event.expression.value;

                        return (
                            <div key={key} className={classes.eventContainer}>
                                <Chip className={classes.chipRoot} label={label} color="primary" />
                                <Typography variant="body1" className={classes.equalSign}>=</Typography>
                                <Chip className={classes.chipRoot} label={expressionValue} color="secondary" />
                            </div>
                        );
                    })}
                </Grid>
            );
        } else {
            return (
                <Grid item xs={12}>
                    <Typography variant="body2">{intl.formatMessage({ id: 'noSelection.basicEvents' })}</Typography>
                </Grid>
            );
        }
    };

    return (
        <ExpansionPanel component={Card} defaultExpanded classes={{ expanded: classes.expansionPanelExpanded }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{intl.formatMessage({ id: 'basicEvents' })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    {renderContent()}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default SelectedBasicEvents;