import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { injectIntl } from "react-intl";
import { withStyles, Theme } from "@material-ui/core/styles/index";
import PhoenixApiManager from "../../../../Api/PhoenixApiManager";
import QuantificationCard from "../Quantification/QuantificationCard";
import { computeHEPFromData } from "../Quantification/QuantificationView";
import TreeReferenceButton from "./TreeReferenceButton";
import HCLTreeMxGraphView from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/MxGraphViews/HCLTreeMxGraphView';

//need to add the title 

// import {HFEType} from "../../../../Types";

const styles = (theme: Theme) => ({});

interface Props {
  classes: { [key: string]: any };
  match: { [key: string]: any };
  intl: { [key: string]: any };
  depth: number;
  cfm: {
    cfms: { [key: string]: any };
    fault_tree_id: number;
    hfe: Partial<HFEType>;
    label: {
      description: string;
      name: string;
    };
    nodes: { [key: string]: any };
    pifs: { [key: string]: any };
    role: string;
    source_type: string;
  };
}

interface State {
  quantificationResult: {
    data: { [key: string]: any };
    loaded: boolean;
    loading: boolean;
  };
  tree: {
    id: number;
    loaded: boolean;
    loading: boolean;
  };
}

class QuantifyCF extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tree: {
        id: null,
        loading: true,
        loaded: false,
        Mdata: null
      },
      isMasteresd: false,
      pathForTitle: null,
      typeOfQuantification: null,
      quantificationResult: {
        data: {},
        loaded: false,
        loading: true,
      },
    };
  }

  // componentDidMount() {
  //   const { match: { params: { cfmName, hfeName, id,cfid } } } = this.props;
  //   PhoenixApiManager.getCFMForModelWithIdAndHFE(cfid, hfeName, cfmName)
  //     .then(response => response.status === 200 && response.json())
  //     .then((cfm) => {
  //       if ('fault_tree_id' in cfm) {
  //         this.setState({
  //           tree: {
  //             id: cfm.fault_tree_id,
  //             loading: false,
  //             loaded: true,
  //           },
  //         }, () => this.fetchQuantificationResults(cfm.fault_tree_id));
  //       } else {
  //         this.setState({
  //           tree: {
  //             id: null,
  //             loading: false,
  //             loaded: false,
  //           },
  //           quantificationResult: {
  //             data: {},
  //             loading: false,
  //             loaded: false,
  //           },
  //         });
  //       }
  //     });
  // }

  getFormattedSequence = (obj, cfId, _case, sequences=null, pathHEP=null) =>{
    // masterESD, critical_function (CF1), paths
    if(_case==="masterESD"){
      console.log(_case)
      this.setState(()=>({
        ...this.state,
        typeOfQuantification: _case,
        quantificationResult: {
          data: obj,
          loaded: true,
          loading: false,
          total_HEP: obj.result_data.sequences?.EndState?.result ? obj.result_data.sequences.EndState?.result : 0
        },
        cfId: cfId,
      }));
      return 
    } else if (_case==="critical_function"){
      localStorage.removeItem("isMasteresd");
      console.log(cfId, 'cfid', obj)
      const critSequence = obj.result_data.critical_functions[cfId].sequences
      const rootSequences = obj.result_data.sequences
      const rootSequencesMap = {}
      Object.keys(rootSequences).forEach(sequenceKey=>{
        for(let cutset of rootSequences[sequenceKey].cutsets){
          if('target' in cutset){
            if(cutset.target in rootSequencesMap){
              rootSequencesMap[cutset.target].push(cutset)
            }else{
              rootSequencesMap[cutset.target] = [cutset]
            }
          }
        }
      })
      Object.keys(rootSequencesMap).forEach(sequenceKey=>{
        rootSequencesMap[sequenceKey].sort(
          // sorts by probability
          (a,b)=> b.probability - a.probability
        ).forEach(
          // reranks based on sorting
          (cutset, index)=> cutset.ranking = index + 1
          )
      })
      Object.keys(critSequence).forEach(key=>{
        if (!critSequence[key].cutsets){
          if(rootSequencesMap[key]){
            critSequence[key] = {...critSequence[key], cutsets: rootSequencesMap[key]}
          } else {
            critSequence[key] = {...critSequence[key], cutsets: []}
          }
        } else {
          critSequence[key] = {...critSequence[key], cutsets: []}
        }
      })
      
      let cfResult = {...obj, result_data: {...obj.result_data, sequences: {...obj.result_data.critical_functions[cfId].sequences }, _totalHep: obj.result_data.critical_functions[cfId].total_HEP }}
      this.setState(()=>({
        ...this.state,
        typeOfQuantification: _case,
        quantificationResult: {
          data: cfResult,
          loaded: true,
          loading: false,
          total_HEP: obj.result_data.critical_functions[cfId].total_HEP
        },
        cfId: cfId,
      }));

      return 
    } else if (_case==="path"){
      localStorage.removeItem("isMasteresd");
      console.log(_case)
      let pathOBJ = {...obj, result_data:{...obj.result_data, sequences: JSON.parse(localStorage.getItem('path_sequence')), }, _totalHep: obj.result_data.critical_functions[cfId].total_HEP}

      this.setState(()=>({
        ...this.state,
        pathForTitle: localStorage.getItem("link_path") ? localStorage.getItem("link_path") : null,
        typeOfQuantification: _case,
        quantificationResult: {
          data: pathOBJ,
          loaded: true,
          loading: false,
          total_HEP: localStorage.getItem('real_path_hep') ? Number(localStorage.getItem('real_path_hep')) : 0
        },
        cfId: cfId,
      }));
      return 
    }
  }

  componentDidMount() {
    // this.getCFs();
    // this.getQuantificationResults();
    this.getMasterESD();
    console.log(localStorage.getItem("main_model_key"), 'this is the ESD')

    const resultDataKay = localStorage.getItem("result_key")
    const mainModelId = localStorage.getItem('main_model_key')
    const objType = localStorage.getItem("obj_data")
    const detailedCfId = localStorage.getItem("detailed_id")

    console.log(resultDataKay, mainModelId)


    PhoenixApiManager.getDetailedMainQuantificationForView(mainModelId, resultDataKay)
    .then((response) => response.status === 200 && response.json())
    .then(response => {
        console.log('uzias', response)
        this.getFormattedSequence(response, detailedCfId, objType, null, null)
    })

    localStorage.removeItem("obj_data"); // removing obj_data here... why it disappears in other place
    localStorage.removeItem("clickedPath");
    localStorage.removeItem("cf_id");
  }

  componentWillUnmount(){
    localStorage.removeItem("isMasteresd");
  }

  getMasterESD = () => {
    const { match: { params: { id } } } = this.props;
    PhoenixApiManager.getMasterEsd(id)
			.then((response) => this.setState({Mdata:response.data}))
  }

  //work flow: get all the CFs--> get the trimmedCRTid for each CF--> add those id and do the map
  getCFs = () => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    PhoenixApiManager.getMainCriticalFunctions(id).then((data) => {
      let temp = [];
      console.log(data);
      for (var i = 0; i < data.data.length; i++) {
        let key = data.data[i].crt_model;
        if (key != null) {
          console.log(key);
          temp.push(key);
          this.state.cf_id_to_names[key] = data.data[i].title;
          // this.fetchTrimmedCRTId(key);
        }
      }
      this.setState({ criticalFunctions: temp });
    });
  };

  getQuantificationResults = () => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    PhoenixApiManager.getMainQuantificationResultsForPhoenixModelWithId(id)
      .then((response) => response.status === 200 && response.json())
      .then((list) => {
        console.log("lets finished thisssssssssssss!", list);
        if (list.results ? list.results.length === 0 : list.length === 0) {
          this.setState({
            quantificationResult: {
              data: {},
              loading: false,
              loaded: true,
            },
          });
        } else {
          this.setState({
            quantificationResult: {
              data: list.results
                ? list.results[list.results.length - 1]
                : list[list.length - 1],
              loading: false,
              loaded: true,
            },
          });
        }
      });
  };

  //this function should be done  on the CF data(CF data has the trimmed id)
  // fetchTrimmedCRTId = () => {
  //   const {
  //     match: {
  //       params: { cf_id },
  //     },
  //   } = this.props;
  //   PhoenixApiManager.getPhoenixModelWithId(cf_id)
  //     .then((response) => response.status === 200 && response.json())
  //     .then((data) => {
  //       const treeId = data.model_data.crt.trimmed_crt_id;
  //       // this.setState({
  //       //   trimmedCRT: {
  //       //     id: treeId,
  //       //     loading: false
  //       //   },
  //       // });
  //       this.state.trimmedCRT_set[treeId] = false;
  //       this.state.cf_id_to_CRTids[cf_id] = treeId;
  //     });
  // };

  //quantifyCRT will take the id for the cf
  quantifyCRT = (configuration: any) => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        quantificationResult: {
          data: {},
          loading: true,
        },
      },
      () => {
        console.log("these are my con", configuration);
        PhoenixApiManager.quantifyMainPhoenixTreeForModelWithId(
          null,
          id,
          configuration
        )
          .then((response) => response.status === 201 && response.json())
          .then((data) => {
            this.setState({
              quantificationResult: {
                data,
                loading: false,
                //@ts-ignore
                loaded: true,
              },
            });
          });
      }
    );
  };

  private handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: configs.currentlySelected,
      anchorEl: e.currentTarget,
    });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private openSettings = (value: boolean) => {
    this.setState({
      addNewConfiguration: true,
      anchorEl: null,
    });
    this.checkForNewConfigClick(value);
  };

  private checkForNewConfigClick = (value: boolean) => {
    this.setState({
      newConfig: value,
    });
  };
  private onConfigurationAdded = () => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: configs.currentlySelected,
      addNewConfiguration: false,
    });
  };

  private resetConfigs = () => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: null,
    });
  };

  private handleSettingsClose = () => {
    const configs = getConfigurations();
    this.setState({
      addNewConfiguration: false,
      currentConfig: configs.currentlySelected,
      configurations: configs.configurations,
    });
  };
  //During render: the critical functions to the expansion panel, and map all the related CRT's id

  getMyConfigs = () => {
    const configs = JSON.parse(localStorage.getItem("configurations"));
    const currentlySelectedKey = configs.currentlySelected;
    const currentlySelectConfig = configs.configurations[currentlySelectedKey];
    return currentlySelectConfig;
  };

  fetchQuantificationResults = (treeId: number) => {
    const {
      match: {
        params: { id, cfid },
      },
    } = this.props;
    PhoenixApiManager.getQuantificationResultsForPhoenixTreeAndModelWithId(
      treeId,
      cfid
    )
      .then((response) => response.status === 200 && response.json())
      .then((list) => {
        if (list.results ? list.results.length === 0 : list.length === 0) {
          this.setState({
            quantificationResult: {
              data: {},
              loading: false,
              loaded: true,
            },
          });
        } else {
          this.setState({
            quantificationResult: {
              data: list.results
                ? list.results[list.results.length - 1]
                : list[list.length - 1],
              loading: false,
              loaded: true,
            },
          });
        }
      });
  };

  isModelQuantifiable = () => {
    const { tree } = this.state;
    return tree.id !== null && tree.id !== undefined;
  };

  quantify = (configuration: any) => {
    const {
      classes,
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        quantificationResult: {
          data: {},
          loading: true,
        },
      },
      () => {
        console.log("these are my con", configuration);
        PhoenixApiManager.quantifyMainPhoenixTreeForModelWithId(
          null,
          id,
          configuration
        )
          .then((response) => response.status === 201 && response.json())
          .then((data) => {
            this.setState({
              quantificationResult: {
                data,
                loading: false,
                //@ts-ignore
                loaded: true,
              },
            });
          });
      }
    );
  };

  computeHEPFromData = (data: any) => {
    if(this.state.quantificationResult.total_HEP !== undefined )
    return `Total HEP = ${(1 - this.state.quantificationResult.total_HEP.toExponential(3))}`
    else { 
      return 0
    }
    // try {
    //   if(localStorage.getItem("isMasteresd")){
    //     const { result } = localStorage.getItem("obj_data").result_data.sequences.EndState
    //     return  result
    //   } else {
        
    //   }
  };
  

  render() {
    const {
      depth,
      match: {
        params: { cfmName, hfeName, id, cfid },
      },
      intl,
      cfm,
    } = this.props;
    const {
      quantificationResult: { loaded, loading, data },
    } = this.state;


    if(Object.keys(this.state.quantificationResult.data).length===0){
      console.log("this is empty")
      return <h2>Loading...</h2>
    }

    return (
      <React.Fragment>
        <BreadcrumbsItem
          to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm`}
          depth={depth - 2}
        >
          {intl.formatMessage({ id: "Critical Function" })}
        </BreadcrumbsItem>
        <BreadcrumbsItem
          to={`/model/${id}/main-analysis/${cfid}/hfe/${hfeName}/cfm/${cfmName}`}
          depth={depth - 1}
        >
          {cfmName}
        </BreadcrumbsItem>
        <BreadcrumbsItem
          to={`/model/${id}/${cfid}/hfe/${hfeName}/cfm/${cfmName}/quantify`}
          depth={depth}
        >
          {intl.formatMessage({ id: "quantify" })}
        </BreadcrumbsItem>
        <TreeReferenceButton
        tooltipTitle={intl.formatMessage({ id: 'checkMasterESD' })}
        title="Master ESD"
        graphView={<HCLTreeMxGraphView data={this.state.Mdata} />} // change this
      />
      {
        this.state.typeOfQuantification == "masterESD" ?
        <QuantificationCard
          loaded={loaded}
          loading={loading}
          data={data}
          onRequestQuantify={(configurations: any) =>
            this.quantify(configurations)
          }
          title={intl.formatMessage({
            id: "Master ESD Quantification" 
          })}
          computeErrorFunc={this.computeHEPFromData}
          quantifyDisabled={!this.isModelQuantifiable()}
          path={this.state.clickedPath}
          cfId={cfid}
        />
        :
        <QuantificationCard
          loaded={loaded}
          loading={loading}
          data={data}
          onRequestQuantify={(configurations: any) =>
            this.quantify(configurations)
          }
          title={this.state.pathForTitle != null ? this.state.pathForTitle : data.result_data.label_map[this.state.cfId].label.name}
          computeErrorFunc={this.computeHEPFromData}
          quantifyDisabled={!this.isModelQuantifiable()}
          path={this.state.clickedPath}
          cfId={cfid}
        />
      }
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(QuantifyCF));
