import React from 'react';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import {Theme, WithStyles, withStyles} from '@material-ui/core/styles';
import createStyles from "@material-ui/core/styles/createStyles";
import { injectIntl } from 'react-intl';

import CutsetView from 'hcl-web-editor/app/components/Model/QuantificationResult/CutsetView';
import ImportanceMeasureView from 'hcl-web-editor/app/components/Model/QuantificationResult/ImportanceMeasures/ImportanceMeasureView';
import { QuantificationResultJSON } from 'hcl-web-editor/app/components/Model/Quantification/QuantificationResultJSON';

//@ts-ignore
import CircularIndeterminate from 'hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate';
//@ts-ignore
import CopyToClipboardChip from 'hcla-web-frontend-primitives/app/components/primitives/Chips/CopyToClipboardChip';

import MoodBadIcon from '@material-ui/icons/MoodBad';
import {Button, Grid, MenuItem, MenuList} from "@material-ui/core";
import {withRouter} from "react-router";
import ActionBarMenu from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ActionBar/ActionBarMenu';

import { Configurations } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Quantification';
import ListItemText from "@material-ui/core/ListItemText";
import ActionBarButton from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/ActionBar/ActionBarButton';
import { getConfigurations, deleteConfiguration, updateCurrentlySelected } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/Utils';
import ArrowDown from '@material-ui/icons/ArrowDropDownTwoTone';
import Close from '@material-ui/icons/CloseTwoTone';
import Edit from '@material-ui/icons/EditTwoTone';
import { TreeNames } from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/TreeDictionaries';
import UserQuantificationSettings from 'hcl-web-editor/app/components/Model/SingleHCLModelView/SingleTreeView/Tools/UserQuantificationSettings';
import AnalysisReferencesHeader from "../../../primitives/AnalysisReferencesHeader";
import PhoenixCutsetItemChip from "./PhoenixCutsetItemChip";
import ResultsIcon from '../../../primitives/Icons/ResultsIcon';
import ActionButtons from "../../../primitives/Button/ActionButtons";

const styles = (theme: Theme) => createStyles({
  root: {
    height: 'calc(100vh - 120px)',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    width: '100%'
  },
  cardHeaderTitleView: {
    margin: theme.spacing(1),
  },
  cardHeaderRoot: {
    flex: '0 0 auto'
  },
  message: {
    paddingBottom: theme.spacing(1) * 4,
    textAlign: 'center',
    color: '#888',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  rootHEP: {
    padding: '24px 0px',
    borderRadius: '24px',
  },
  cardHeaderAction: {
    marginTop: theme.spacing(1)
  },

  errorView: {
    position: "relative",
    height: "100%"
  },

  errorViewContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center"
  },

  menu: {
    maxHeight: '30vh',
    overflowY: 'auto',
  },

  allConfigs: {
    fontSize: '15px',
    fontWeight: 500,
  },

  newConfiguration: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
  },

  editIcon: {
    padding: '0 8px 0 8px',
    marginRight: '1rem',
    width: '2em',
    minWidth: 'unset',
  },

  deleteIcon: {
    padding: '0 8px 0 8px',
    width: '2em',
    minWidth: 'unset',
  },

  downIcon: {
    width: '2em',
    minWidth: 'unset',
  },

});

interface QuantificationCardProps extends WithStyles<typeof styles>, ReactIntl.InjectedIntlProps {
  loading: boolean;
  data: QuantificationResultJSON;
  onRequestQuantify: (configuration?: any) => void;
  computeErrorFunc: (data: QuantificationResultJSON) => {};
  title?: string;
  quantifyDisabled?: boolean;
  hfe?: any;
  cfm?: any;
  treeId: number;
  path?: string;
  cfId?: number;
}

interface State {
  addNewConfiguration: boolean;
  configurations: { [key: string]: Configurations };
  tabIdx: number;
  currentConfig: string;
  editableConfig: { [key: string]: Configurations };
  anchorEl: EventTarget & HTMLElement;
  currentKey: string;
  newConfig: boolean;
}

class QuantificationCard extends React.Component<QuantificationCardProps, State> {
  constructor(props: QuantificationCardProps) {
    super(props);
    this.state = {
      tabIdx: 0,
      addNewConfiguration: false,
      configurations: {},
      anchorEl: null,
      currentConfig: null,
      editableConfig: {},
      currentKey: null,
      newConfig: false,
    };
  }

  componentDidMount(): void {
    this.onConfigurationAdded();
  }

  private handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: configs.currentlySelected,
      anchorEl: e.currentTarget,
    });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private openSettings = (value: boolean) => {
    this.setState({
      addNewConfiguration: true,
      anchorEl: null,
    });
    this.checkForNewConfigClick(value);
  };

  private checkForNewConfigClick = (value: boolean) => {
    this.setState({
      newConfig: value,
    });
  };

  private onConfigurationAdded = () => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: configs.currentlySelected,
      addNewConfiguration: false,
    });
  };

  private resetConfigs = () => {
    const configs = getConfigurations();
    this.setState({
      configurations: configs.configurations,
      currentConfig: null,
    });
  };

  private handleSettingsClose = () => {
    const configs = getConfigurations();
    this.setState({
      addNewConfiguration: false,
      currentConfig: configs.currentlySelected,
      configurations: configs.configurations,
    });
  };

  getQuantificationButton = () => { // getting/generating the quantification button
    const { classes, intl, loading, quantifyDisabled, onRequestQuantify, match: { params: { id,cfid } }, treeId } = this.props;
    const {
      addNewConfiguration,
      configurations,
      anchorEl,
      currentConfig,
      editableConfig,
      currentKey,
      newConfig,
    } = this.state;
    const configuration = configurations[currentConfig] ? configurations[currentConfig].configuration : null;
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          disabled={quantifyDisabled || loading}
          onClick={() => onRequestQuantify(configuration)}
        >
          <ResultsIcon
            //@ts-ignore
            className={classes.extendedIcon}
            disabled={quantifyDisabled || loading}
          />
          {intl.formatMessage({ id: 'results.quantify' })}
        </Button>
        <ActionBarButton
          onClick={this.handleClick}
          color="primary"
          variant="contained"
          classOverride={classes.downIcon}
        >
          <ArrowDown />
        </ActionBarButton>
        <ActionBarMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          placementOverride="bottom-end"
        >
          <MenuList dense disablePadding className={classes.menu}>
            <MenuItem onClick={() => {this.setState({ currentKey: null}, () => this.openSettings(true));}}>
              <ListItemText primary='+ New Configuration' className={classes.newConfiguration} disableTypography />
            </MenuItem>
            {Object.keys(configurations || {}).map((key: any) => (
              <MenuItem key={key} selected={currentConfig === key} onClick={() => { updateCurrentlySelected(key); this.setState({ currentConfig: key }, () => { this.handleClose(); }); }}>
                <ListItemText primary={key} className={classes.allConfigs} disableTypography />
                <ActionBarButton
                  onClick={e => {
                    e.stopPropagation();
                    const config = getConfigurations();
                    this.setState({
                      currentKey: key,
                      editableConfig: { [key]: config.configurations[key] },
                    }, () => this.openSettings(false));
                  }}
                  classOverride={classes.editIcon}
                >
                  <Edit />
                </ActionBarButton>
                <ActionBarButton
                  onClick={e => { deleteConfiguration(e, key); this.resetConfigs(); }}
                  classOverride={classes.deleteIcon}
                >
                  <Close />
                </ActionBarButton>
              </MenuItem>
            ))}
          </MenuList>
        </ActionBarMenu>
        <UserQuantificationSettings
          modelId={id}
          treeId={treeId}
          treeType={TreeNames.FAULT_TREE}
          open={addNewConfiguration}
          onClose={this.handleSettingsClose}
          editableConfig={editableConfig}
          currentKey={currentKey}
          newConfig={newConfig}
          quantifyOverride={() => {
            onRequestQuantify(configuration);
            this.handleSettingsClose();
          }}
        />
      </React.Fragment>
    );
  };

  getImportanceView = () => {
    const { classes, loading, data } = this.props;
    if (data.result_data && data.result_data['importance']) {
      return (<ImportanceMeasureView noSlide loading={loading} data={data} map={data.result_data.label_map} />);
    }
    return (
      <div className={classes.root}>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          No Importance Measures Specified
        </Typography>
      </div>
    );
  };

  getErrorView = () => {

    const { classes, intl } = this.props;

    return (
      <div className={classes.errorView}>
        <div className={classes.errorViewContent}>
          <MoodBadIcon color="secondary" fontSize="large" />
          <Typography variant="h5">{intl.formatMessage({ id: 'results.empty.message.primary' })}</Typography>
          <Typography>{intl.formatMessage({ id: 'results.empty.message.secondary' })}</Typography>
        </div>
      </div>
    );
  };

  getQuantificationResultView = () => {
    const { classes, loading, data, intl } = this.props;
    const { tabIdx } = this.state;
    console.log(data,"hmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm")

    if (loading) return <React.Fragment />;

    // data is {}
    if (Object.keys(data).length === 0) return this.getErrorView(); // use this if statement as ternary operator

    return (
      <div>
        <Tabs
          value={tabIdx}
          indicatorColor="primary"
          textColor="secondary"
          centered
          variant="fullWidth"
        >
          <Tab
            label={intl.formatMessage({ id: 'FaultTreeResultView.Columns.Header.minimalCut' })}
            onClick={e => this.setState({ tabIdx: 0 })}
          />
          <Tab
            label={intl.formatMessage({ id: 'results.importanceMeasures' })}
            onClick={e => this.setState({ tabIdx: 1 })}
            disabled
          />
        </Tabs>
        <div className={classes.tableContainer}>
          {
            tabIdx === 0 && (
              <CutsetView
                resultData={"sequences" in data.result_data ? data.result_data : {...data.result_data, sequences: {}} }
                type={data.configuration.quantify.type}
                loading={loading}
                cutsetItemChip={PhoenixCutsetItemChip}
              />
            )}
          {tabIdx === 1 && this.getImportanceView()}
        </div>
      </div>
    );
  };

  getCardHeaderTitleView = () => { // important one
    const { classes, title, computeErrorFunc, loading, data, path, cfId } = this.props;
    console.log("cfID here", cfId)
    return (
      <div className={classes.cardHeaderTitleView}>
        <Grid container justify="space-between" alignItems="flex-start" direction="column">
          <Grid item>
          {/* <Typography variant="h4" gutterBottom>{data.result_data.label_map[cfId].label.name}</Typography> */}
            <Typography variant="h4" gutterBottom>{title}</Typography>
          </Grid>
          {/* {
            isNaN(cfId) ? (null) :
            <Grid item>
              <Typography variant="h5">{data.result_data.label_map[cfId].label.name}</Typography>
          </Grid>
          } */}
          <Grid item>
            <Typography variant="h5">{path}</Typography>
          </Grid>
          <Grid item container justify="flex-end">
            {
              loading ? (
              <Chip
                  className={classes.rootHEP}
                  color="primary"
                  variant="outlined"
                  label={<CircularIndeterminate size={28} color="secondary" />}
              />
              )
              // computeErrorFunc(data)
              // <span style={{position: 'absolute', right: '3.05rem'}} ><CopyToClipboardChip color="secondary" variant="outlined" label={ computeErrorFunc(data)} /></span> 
              : Object.keys(data).length === 0 ? 
                computeErrorFunc(data)
                :   <span style={{ position: 'relative', right: '0', display: 'flex', justifyContent: 'flex-end' }}>
                <CopyToClipboardChip 
                  color="secondary" 
                  variant="outlined" 
                  label={ computeErrorFunc(data) } 
                />
              </span>
            }
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { classes, match: { params: { id,cfid } }, hfe, cfm } = this.props;
    const crtData = !hfe && !cfm ? { cfid } : null;
    const hfeData = hfe ? { cfid: hfe.fault_tree_id } : null;
    const cfmTitle = cfm ? cfm.label.name : null;
    
    return (
      <React.Fragment>
        { // add logic here to first check if crtData === null
        // crtData === null ? (null) :
        // crtData.cfid === undefined ? (null) :
        // uncommenting the above two lines gets rid of the issue where things aren't quantifying right?
        <AnalysisReferencesHeader // this is generating the crt button
          crt={crtData}
          hfe={hfeData}
          cfm={cfmTitle && {
            treeId: cfm.fault_tree_id,
            label: cfmTitle
          }}
          others={
            <ActionButtons
              others={this.getQuantificationButton()}
            />
          }
          inPaperContainer
        />
      }
        <Card className={classes.root}>
          <CardHeader title={this.getCardHeaderTitleView()} classes={{ root: classes.cardHeaderRoot }} />
          {this.getQuantificationResultView()}
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(injectIntl(QuantificationCard)));
