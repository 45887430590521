import React,{useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { injectIntl } from "react-intl";

import CircularIndeterminate from "hcla-web-frontend-primitives/app/components/primitives/Progress/CircularIndeterminate";
import ViewWithDrawer from "hcla-web-frontend-primitives/app/components/primitives/Views/ViewWithDrawer";
import ModelViewNoMatch from "hcla-web-frontend-primitives/app/components/primitives/Error/ModelViewNoMatch";

import Overview from "./Overview/Overview";
import SettingsView from "./Settings/SettingsView";
import DrawerList from "./DrawerList";
import AnalysisHeader from "./Analysis/AnalysisHeader";
import ModelHeader from "./ModelHeader";
import QuantifyHeader from "./Quantification/QuantificationView";
import PhoenixApiManager from "../../../Api/PhoenixApiManager";
import cfModelContext from "../cfModelContext";
import PhoenixModelContext from "../PhoenixModelContext";
import { ReportSelectionProvider } from "../Report/ReportSelectionContext";
import ApiManager from "hcla-web-frontend-primitives/app/Api/ApiManager";
import QuantificationView from "./Quantification/QuantificationView";
import {PhoenixEventTreeMxGraphView} from "./PhoenixTreeMxGraphView";
import MasterESD_main from "../MasterESD_main";
import SingleCfQuantificationView from "./Quantification/SingleCfQuantificationView";
import QuantifyCF from "./Quantification/QuantifyCF"
import DependencyPifSelection from "./Analysis/Dependency/DependencyPifSelection";

class SinglePhoenixMainModelView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      refresh: this.fetchModel,
      isSavingInProgress: false,
      isLoading: true,
      loaded: false,
      allCFs:null,
    };
  }

  componentDidMount() {
    this.fetchModel();
    this.getMainCriticalFunctions();
  }

  onRequestSave = (event, data) => {
    const payload = data
    return this.updateModelData(event, payload);
  };

  updateModelData = (event, payload) => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return new Promise((resolve) => {
      this.setState({ isSavingInProgress: true }, () => {
        PhoenixApiManager.settingPartialUpdateMainModel(id, payload)
          // .then(response => response.status === 200)
          .then((res) => {
            this.setState({ details: res.data, isSavingInProgress: false }, 
              () => resolve(res));
          });
      });
    });

  };


  getMainCriticalFunctions=()=>{
    const{match:{params:{id}}}=this.props;
    PhoenixApiManager.getMainCriticalFunctions(id)
      .then((data) => {
        this.setState({allCFs:data.data})
      })
  }


  fetchModel = () => {
    const {
      match: {
        params: { id }
      },
      intl
    } = this.props;
    return PhoenixApiManager.openMainModel(id, {
      successText: intl.formatMessage({ id: "api.success.modelData" }),
      failText: intl.formatMessage({ id: "api.error.modelData" }),
      autoHideDuration: 1000
    })
      .then(response => (response.status === 200 ? response : false,
          
          this.setState({ details: response.data,isLoading: false,loaded: response.data !== false}),
          console.log("DETAILS WE HAVE",this.state.details)
          ))
      // .then((data) =>
      //   this.setState({
      //     details: data,
      //     isLoading: false,
      //     loaded: data !== false
      //   })
      // );
  };
//
//Note: in here, I created a new route for detailed overview of a certain model's certain CF
//The URL is model/modelId/cfIddetailed-quantification
  render() {
    const { isLoading, loaded, details } = this.state;

    if (isLoading) {
      return (
        <ViewWithDrawer padding drawerItems={null}>
          <CircularIndeterminate size={80} color="secondary" />
        </ViewWithDrawer>
      );
    }

    // self is not in assigned users
    // redirect to model list view
    if (details.assigned_users.length > 0 && !details.assigned_users.includes(ApiManager.getCurrentUser().user_id)) {
      return <Redirect to="/" />;
    }

    if (!isLoading && !loaded) {
      return <Redirect to="/model/not-found/" />;
    }

    const {
      depth,
      match: {
        params: { id }
      },
      intl,
      cfid,
    } = this.props;
    return (
      <PhoenixModelContext.Provider value={this.state}>
        <ReportSelectionProvider>
          <ViewWithDrawer
            padding
            drawerItems={<DrawerList details={details} {...this.props} />}
          >
            <BreadcrumbsItem to={`/model/${id}/main-overview`} depth={depth}>
              {details.title}
            </BreadcrumbsItem>
            <ModelHeader id={Number(id)} />
            <Switch>
              <Route
                exact
                path="/model/:id/main-overview/"
                render={prop => <Overview {...prop} depth={depth + 1} />}
              />
              <Route
                exact
                path="/model/:id/main-master-esd"
                render={prop => <MasterESD_main {...prop} depth={depth + 1} details={details} id={Number(id)}/>}
              />
              <Route
                exact
                path="/model/:id/main-quantification"
                render={prop => <QuantificationView {...prop} />}
              />
              <Route
                exact
                path="/model/:id/main-settings"
                render={prop => (
                  <SettingsView
                    {...prop}
                    onRequestSave={this.onRequestSave}
                    depth={depth + 1}
                  />
                )}
              />
              <Route
                exact
                path="/model/:id/main-analysis"
                render={prop => <AnalysisHeader {...prop} />}
              />
              <Route
                exact
                path="/model/:id/main-dependency"
                render={prop => <DependencyPifSelection {...prop} />}
              />
              <Route
                exact
                path="/model/:id/:cfid/main-progress"
                render={prop => <AnalysisHeader {...prop} />}
              />
              <Route
                  exact
                  path="/model/:id/main-analysis/:cfid/quantify/"
                  render={prop => (
                    <QuantifyCF
                      {...prop}
                      depth={depth + 1}
                    />
                  )}
                />
              <Route
                path="/model/:id/main-analysis/hfe/"
                render={prop => <AnalysisHeader {...prop} />}
              />
              <Route
                path="/model/:id/main-analysis/:cfid/crt/"
                render={prop => <AnalysisHeader {...prop} />}
              />

              <Route
                path="/model/:id/main-analysis/:cfid/bp/"
                render={prop => <AnalysisHeader {...prop} />}
              />

              <Route
                path="/model/:id/main-analysis/:cfid/hfe/"
                render={prop => <AnalysisHeader {...prop} />}
              />

              <Route
                path="/model/:id/main-analysis/:cfid/hfe/cfm/pif"
                render={prop => <AnalysisHeader {...prop} />}
              />
              <Route
                path="/model/:id/:cfid/detailed-quantification/"
                render={prop => <SingleCfQuantificationView {...prop} />}
              />
              <Route component={ModelViewNoMatch} />
            </Switch>
          </ViewWithDrawer>
        </ReportSelectionProvider>
      </PhoenixModelContext.Provider>
    );
  }
}

SinglePhoenixMainModelView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string
  }).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  depth: PropTypes.number.isRequired,

};

export default injectIntl(SinglePhoenixMainModelView);
